import DatePicker from 'components/DatePicker';
import InputGroup from 'components/Input';
import {SegmentAttributesEditorContext} from 'components/SegmentAttributesEditor';
import SelectGroup from 'components/Select';
import {debounce} from 'helpers/utils';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {customAttributeService, eventPropertyService} from 'services';
import {CATEGORY_CUSTOM_ATTRIBUTES} from 'services/segment';
import {AttributeContext} from '../..';
import {OPERAND_OPTIONS, getTypeFromAttribute} from '../AttributeOperand';
import './_Styles.scss';

const string2date = (str) => {
  if (typeof str === 'string') {
    return new Date(str);
  }
  return str;
};

const StringAttributeValue = ({disableAutocomplete = false}) => {
  const {
    trackedEvents,
    segmentioEvents,
    customerioEvents,
    posthogEvents,
    jimoApiEvents,
  } = useContext(SegmentAttributesEditorContext);
  const {attribute, updateAttribute} = useContext(AttributeContext);

  const [loadingValues, setLoadingValues] = useState(false);
  const [autocompleteValues, setAutocompleteValues] = useState([]);

  const {values, property} = attribute;
  const v = values[0] || '';
  const [operand, operandType] =
    attribute.op != null ? attribute.op.split(';') : [];

  const eventSelected = [
    ...trackedEvents,
    ...segmentioEvents,
    ...customerioEvents,
    ...posthogEvents,
    ...jimoApiEvents,
  ].find((e) =>
    attribute.eventId != null
      ? e.uid === attribute.eventId
      : e.name === attribute.name
  );
  const propertySelected = eventSelected?.properties?.find(
    (p) => p.name === property
  );
  const propertyId = propertySelected?.uid;

  const debouncedFetchCustomAttributeValues = useMemo(
    () =>
      debounce(async (search) => {
        setLoadingValues(true);
        const values = await customAttributeService
          .getCustomAttributeValues(attribute.customAttributeId, {
            skip: 0,
            take: 20,
            search,
          })
          .then((data) => data.data);

        setAutocompleteValues(values);
        setLoadingValues(false);
      }, 200),
    []
  );

  const debouncedFetchEventPropertyValues = useMemo(
    () =>
      debounce(async (search) => {
        setLoadingValues(true);
        const values = await eventPropertyService
          .getEventPropertyValues(propertyId, {
            skip: 0,
            take: 20,
            search,
          })
          .then((data) => data.data);

        setAutocompleteValues(values);
        setLoadingValues(false);
      }, 200),
    []
  );

  useEffect(() => {
    if (
      (attribute.type !== CATEGORY_CUSTOM_ATTRIBUTES && propertyId == null) ||
      disableAutocomplete === true
    ) {
      return;
    }
    if (v.length > 0) {
      setLoadingValues(true);
    }
    if (attribute.category === CATEGORY_CUSTOM_ATTRIBUTES) {
      debouncedFetchCustomAttributeValues(v);
    } else if (propertyId != null) {
      debouncedFetchEventPropertyValues(v);
    }
  }, [v, propertyId]);

  return (
    <InputGroup
      className="input-values-str"
      autocomplete={
        (attribute.type === CATEGORY_CUSTOM_ATTRIBUTES || propertyId != null) &&
        disableAutocomplete === false
      }
      autocompleteItems={autocompleteValues.map((v) => ({
        id: v.uid,
        content: v.value,
        value: v,
      }))}
      placeholder={
        operandType === 'ARRAY' ? 'value1,value2,value3' : 'enter value...'
      }
      value={v}
      loading={loadingValues}
      onChange={({target}) =>
        updateAttribute({
          ...attribute,
          values: [target.value],
        })
      }
      onAutocompleteSelected={(item) => {
        updateAttribute({
          ...attribute,
          values: [item.value],
        });
      }}
    />
  );
};

const DateAttributeValue = ({
  index = 0,
  event = false,
  eventOccurrence = false,
}) => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {
    values: primaryValues,
    eventValues,
    op: primaryOp,
    eventOp,
    eventOccurrenceValues,
  } = attribute;

  const values =
    event === true
      ? eventValues
      : eventOccurrence === true
      ? eventOccurrenceValues
      : primaryValues;
  const op = event === true || eventOccurrence === true ? eventOp : primaryOp;
  const fieldToUpdate =
    event === true
      ? 'eventValues'
      : eventOccurrence === true
      ? 'eventOccurrenceValues'
      : 'values';

  const [operand] = op?.split(';') || [];

  const DatepickerCustomInput = forwardRef(({value, onClick}, ref) => (
    <InputGroup
      innerRef={ref}
      value={value}
      onClick={onClick}
      labelTextRight={
        <div
          className="reset-value-btn"
          onClick={(e) => {
            e.stopPropagation();

            updateAttribute({
              ...attribute,
              ...(event === true
                ? {
                    eventValues: index === 1 ? [eventValues[0]] : [],
                  }
                : eventOccurrence === true
                ? {
                    eventOccurrenceValues: index === 1 ? [eventValues[0]] : [],
                  }
                : {
                    values: index === 1 ? [values[0]] : [],
                  }),
            });
          }}>
          <i className="icon-close" />
        </div>
      }
    />
  ));

  const pickerProps = [
    OPERAND_OPTIONS.OP_IS_BETWEEN.value,
    OPERAND_OPTIONS.OP_IS_NBETWEEN.value,
  ].includes(operand)
    ? {
        selectsRange: true,
        startDate: values[0] ? string2date(values[0]) : null,
        endDate: values[1] ? string2date(values[1]) : null,
      }
    : {
        selected: values[index] ? string2date(values[index]) : null,
      };

  return (
    <DatePicker
      popperPlacement="top"
      monthsShown={2}
      dateFormat="MMM dd, yyyy"
      {...pickerProps}
      onChange={(date) => {
        if (Array.isArray(date)) {
          updateAttribute({
            ...attribute,
            [fieldToUpdate]: [date[0]?.toISOString(), date[1]?.toISOString()],
          });
        } else {
          updateAttribute({
            ...attribute,
            [fieldToUpdate]:
              index === 0
                ? [date.toISOString()]
                : [values[0], date.toISOString()],
          });
        }
      }}
      customInput={<DatepickerCustomInput />}
    />
  );
};

export const NumberAttributeValue = ({
  index = 0,
  label = null,
  event,
  eventOccurrence,
}) => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {values, eventValues, eventOccurrenceValues, eventOp, op} = attribute;

  const operand = event === true || eventOccurrence === true ? eventOp : op;
  const valuesArr =
    event === true
      ? eventValues
      : eventOccurrence === true
      ? eventOccurrenceValues
      : values;
  const fieldToUpdate =
    event === true
      ? 'eventValues'
      : eventOccurrence === true
      ? 'eventOccurrenceValues'
      : 'values';

  const [operandValue] = operand?.split(';') || [];

  return (
    <>
      <InputGroup
        placeholder={label == null ? 'enter value...' : null}
        value={valuesArr[index] || ''}
        type="number"
        onChange={({target}) =>
          // only update the first value
          updateAttribute({
            ...attribute,
            [fieldToUpdate]:
              index === 0
                ? [target.value, valuesArr[1]]
                : [valuesArr[0], target.value],
          })
        }
        labelTextRight={label}
      />
      {[
        OPERAND_OPTIONS.OP_IS_BETWEEN.value,
        OPERAND_OPTIONS.OP_IS_NBETWEEN.value,
      ].includes(operandValue) && (
        <InputGroup
          placeholder="enter value..."
          value={valuesArr[1] || ''}
          type="number"
          onChange={({target}) =>
            updateAttribute({
              ...attribute,
              [fieldToUpdate]: [valuesArr[1], target.value],
            })
          }
        />
      )}
    </>
  );
};

export const BooleanAttributeValue = () => {
  const {attribute, updateAttribute} = useContext(AttributeContext);
  const {values} = attribute;

  const options = [
    {value: 'true', label: 'true'},
    {value: 'false', label: 'false'},
  ];

  const selectedValue = options.find((o) => o.value === values[0]);

  return (
    <SelectGroup
      menuPortalTarget={document.body}
      options={options}
      onChange={({value}) =>
        updateAttribute({
          ...attribute,
          values: [value],
        })
      }
      value={selectedValue}
    />
  );
};

const AttributeValue = ({
  type = null,
  label = null,
  event = false,
  eventOccurrence = false,
}) => {
  const {attribute} = useContext(AttributeContext);
  const {op: operand, eventOp} = attribute;
  const attributeType =
    (event === true || eventOccurrence === true) && type == null
      ? 'event'
      : type || getTypeFromAttribute(attribute);

  const op = event === true || eventOccurrence === true ? eventOp : operand;

  if (
    (op == null && attributeType == null) ||
    [
      OPERAND_OPTIONS.OP_IS_EMPTY.value,
      OPERAND_OPTIONS.OP_NIS_EMPTY.value,
    ].includes(op) ||
    [OPERAND_OPTIONS.OP_ARRAY_EMPTY, OPERAND_OPTIONS.OP_ARRAY_NEMPTY].includes(
      op
    )
  ) {
    return <></>;
  }

  let content;

  if (attributeType === 'string') {
    content = <StringAttributeValue />;
  } else if (attributeType === 'date') {
    if (
      [
        OPERAND_OPTIONS.OP_DATE_RELATIVE_DAYS_IN_RANGE.value,
        OPERAND_OPTIONS.OP_DATE_RELATIVE_DAYS_NIN_RANGE.value,
      ].includes(op)
    ) {
      content = (
        <NumberAttributeValue
          label={label || 'days'}
          event={event}
          eventOccurrence={eventOccurrence}
        />
      );
    } else {
      content = (
        <DateAttributeValue event={event} eventOccurrence={eventOccurrence} />
      );
    }
  } else if (attributeType === 'number') {
    content = (
      <NumberAttributeValue
        label={label}
        event={event}
        eventOccurrence={eventOccurrence}
      />
    );
  } else if (attributeType === 'mixed') {
    const [operand, operandType] = op?.split(';') || [];

    if (
      [
        OPERAND_OPTIONS.OP_IS_EMPTY.value,
        OPERAND_OPTIONS.OP_NIS_EMPTY.value,
      ].includes(operand) ||
      [
        OPERAND_OPTIONS.OP_ARRAY_EMPTY.value,
        OPERAND_OPTIONS.OP_ARRAY_NEMPTY.value,
      ].includes(operand)
    ) {
      content = <></>;
    } else if (operandType === 'STRING') {
      content = <StringAttributeValue />;
    } else if (operandType === 'DATE') {
      if (
        [
          OPERAND_OPTIONS.OP_DATE_RELATIVE_DAYS_IN_RANGE.value,
          OPERAND_OPTIONS.OP_DATE_RELATIVE_DAYS_NIN_RANGE.value,
        ].includes(operand)
      ) {
        content = (
          <NumberAttributeValue
            label={label}
            event={event}
            eventOccurrence={eventOccurrence}
          />
        );
      } else {
        content = (
          <DateAttributeValue event={event} eventOccurrence={eventOccurrence} />
        );
      }
    } else if (operandType === 'NUMBER') {
      content = (
        <NumberAttributeValue
          label={label}
          event={event}
          eventOccurrence={eventOccurrence}
        />
      );
    } else if (operandType === 'BOOLEAN') {
      content = <BooleanAttributeValue />;
    } else if (operandType === 'ARRAY') {
      content = <StringAttributeValue disableAutocomplete={true} />;
    }
  } else if (attributeType === 'event') {
    if (op === OPERAND_OPTIONS.OP_EVENT_SINCE.value) {
      content = (
        <DateAttributeValue event={event} eventOccurrence={eventOccurrence} />
      );
    } else if (op === OPERAND_OPTIONS.OP_EVENT_IN_THE_LAST.value) {
      content = (
        <NumberAttributeValue
          label="days"
          event={event}
          eventOccurrence={eventOccurrence}
        />
      );
    }
  }
  // else if () {
  //   return {
  //     content = <StringAttributeValue />;
  //   }
  // }

  return <div className="attribute-value">{content}</div>;
};

export default AttributeValue;
