import classnames from 'classnames';
import Button from 'components/Button';
import Tabs from 'components/Tabs';
import {PermissionsPeople} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {useEffect} from 'react';
import {useQuery} from 'react-query';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_SEGMENTS,
  ROUTE_USERS,
  ROUTE_USERS_IMPORTS,
  ROUTE_USERS_IMPORT_CSV,
  ROUTE_USERS_SEGMENT_WITH_ID,
} from 'router/routes.const';
import {segmentService} from 'services';
import './_Styles.scss';
import AllPeople from './components/AllPeople';
import ListSegments from './components/ListSegments';
import Imports from './scenes/Imports';

const TAB_PEOPLE = 'Users';
const TAB_SEGMENTS = 'Segments';
const TAB_IMPORTS = 'Imports';

const Users = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();

  const {
    data: segments,
    isLoading,
    refetch: refetchSegments,
  } = useQuery({
    queryKey: ['segments'],
    queryFn: () =>
      segmentService.getSegments({
        relations: ['analytics', 'author', 'lastEditor'],
      }),
    refetchOnWindowFocus: false,
    onSuccess: (segments) => {
      window.jimo.push([
        'set',
        'user:attributes',
        [{countSegments: segments.length}],
      ]);
    },
  });

  let tab;

  if (routeMatch.path === ROUTE_USERS) {
    tab = TAB_PEOPLE;
  }
  if (routeMatch.path === ROUTE_SEGMENTS) {
    tab = TAB_SEGMENTS;
  }
  if (routeMatch.path === ROUTE_USERS_IMPORTS) {
    tab = TAB_IMPORTS;
  }

  const handleTabChange = (tab) => {
    if (tab === TAB_PEOPLE) {
      history.push(ROUTE_USERS);
    }
    if (tab === TAB_SEGMENTS) {
      history.push(ROUTE_SEGMENTS);
    }
    if (tab === TAB_IMPORTS) {
      history.push(ROUTE_USERS_IMPORTS);
    }
  };

  useEffect(() => {
    if (tab === TAB_SEGMENTS) {
      refetchSegments();
    }
  }, [tab]);

  const canCreateSegment = hasPermissions(
    PermissionsPeople.SEGMENT_CREATE_EDIT
  );

  const segmentsCount = segments?.length || 0;

  return (
    <div
      className={classnames('s-users fade-in', {
        'scene-imports': tab === TAB_IMPORTS,
      })}>
      <div className="header">
        <div className="title-3">Users & Segments</div>
        <div className="actions-wrapper">
          <Button
            className="new-segment-btn"
            primary={routeMatch.path === ROUTE_USERS_IMPORTS}
            iconLeft="isax isax-document-upload"
            thin
            onClick={() => {
              history.push(ROUTE_USERS_IMPORT_CSV);
            }}>
            Import users from CSV
          </Button>
          {routeMatch.path !== ROUTE_USERS_IMPORTS && (
            <>
              {canCreateSegment && (
                <Button
                  className="new-segment-btn"
                  iconLeft="icon-plus"
                  reverted
                  primary
                  thin
                  onClick={() => {
                    history.push(ROUTE_USERS_SEGMENT_WITH_ID('new'));
                  }}>
                  Create segment
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="tabs-wrapper">
        <Tabs defaultTab={tab} onTabChange={handleTabChange}>
          <div label={TAB_PEOPLE} />
          <div
            label={TAB_SEGMENTS}
            className="tab-segments"
            extraLabel={
              <div className="segments-count">
                <span className="count">{segmentsCount}</span>
              </div>
            }
          />
          <div label={TAB_IMPORTS} />
        </Tabs>
      </div>
      {tab === TAB_PEOPLE && <AllPeople />}
      {tab === TAB_SEGMENTS && (
        <ListSegments
          segments={segments}
          isLoading={isLoading}
          refetchSegments={refetchSegments}
        />
      )}
      {tab === TAB_IMPORTS && <Imports />}
    </div>
  );
};

export default Users;
