import Button from 'components/Button';
import Divider from 'components/Divider';
import {Modal} from 'components/Modal';
import {addFlag, hasFlag} from 'helpers/bitwise';
import React, {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {AudienceContext} from 'scenes/PokeAudience';
import EventConditions from 'scenes/SuccessTracker/components/Event/components/EventConditions';
import {
  buildLogic,
  isEventValid,
} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import EventsList from 'scenes/TrackerBuilder/components/ModalAddStepEvent/components/EventsList';
import {EventItem} from 'scenes/TrackerBuilder/components/TrackerBuilderSingleEvent';
import {eventService} from 'services';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_SOURCE_JIMO,
  EVENT_SOURCE_JIMO_API,
  EVENT_SOURCE_MIXPANEL,
  EVENT_SOURCE_POSTHOG,
  EVENT_SOURCE_SEGMENT,
  F_EVENT_HAS_BEEN_SENT_BY_SDK,
} from 'services/event';
import {
  F_OPTION_IGNORE_RATE_LIMITING,
  TRIGGER_TYPE_EVENT,
} from 'services/evolution';
import './_Styles.scss';

const EventModal = ({isOpen, onRequestClose, ...rest}) => {
  const {evolution: evolutionContext, setEvolution: setEvolutionContext} =
    useContext(AudienceContext);

  const [evolution, setEvolution] = useState(evolutionContext);
  const [conditions, setConditions] = useState(
    evolutionContext?.onTheFlyEvent?.conditions || []
  );
  const [logic, setLogic] = useState(
    buildLogic(evolutionContext?.onTheFlyEvent)
  );

  const {onTheFlyEvent, event} = evolution;

  let {data: events = [], isLoading: isLoadingEvents} = useQuery({
    queryKey: 'trackedEvents',
    queryFn: () => eventService.getEvents({}),
    refetchOnWindowFocus: false,
  });

  const handleSave = () => {
    setEvolutionContext({
      ...evolutionContext,
      event: evolution.event,
      eventId: evolution.eventId,
      onTheFlyEvent:
        evolution.onTheFlyEvent != null
          ? {
              ...evolution.onTheFlyEvent,
              conditions: conditions,
              logic: logic,
            }
          : null,
      triggerType: TRIGGER_TYPE_EVENT,
      optionsFlags: addFlag(
        F_OPTION_IGNORE_RATE_LIMITING,
        evolution.optionsFlags
      ),
    });

    onRequestClose();
  };

  const canSave = event != null || onTheFlyEvent != null;

  const isValid =
    onTheFlyEvent != null
      ? isEventValid(conditions, logic).isValid
      : event != null;

  return (
    <Modal
      className="modal-experience-event"
      title={
        <>
          <i className="icon-pointer" />
          On event
        </>
      }
      footer={
        <>
          <Button muted className="cancel-btn" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            reverted
            primary
            onClick={handleSave}
            disabled={canSave !== true || isValid !== true}>
            Save
          </Button>
        </>
      }
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}>
      <div className="event-modal-content-wrapper">
        <div className="event-selector">
          <div className="event-selector-header">
            <div className="event-selector-title">
              Event to trigger your experience
            </div>
            <div className="event-selector-description">
              Your experience will be triggered after this event. You can select
              an existing event or create a new one on the fly. Select one.
            </div>
          </div>
          {onTheFlyEvent != null ? (
            <>
              <EventConditions
                conditions={conditions}
                setConditions={setConditions}
                logic={logic}
                setLogic={setLogic}
                borderless
                compact
                omitTypes={[EVENT_CONDITION_TYPE_DELAY]}
              />
              <Button
                className="remove-event-btn"
                thin
                iconRight="isax isax-trash"
                onClick={() => {
                  setEvolution({
                    ...evolution,
                    onTheFlyEvent: null,
                  });
                  setConditions([]);
                  setLogic(buildLogic());
                }}>
                Remove on the fly event
              </Button>
            </>
          ) : event != null ? (
            <div className="event-list">
              <div className="event-wrapper" key={event.uid}>
                <EventItem
                  event={event}
                  className="selected-event"
                  onDelete={() => {
                    setEvolution({
                      ...evolution,
                      event: null,
                      eventId: null,
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <Divider />
              <EventsList
                events={events.filter(
                  (event) =>
                    event.source === EVENT_SOURCE_JIMO ||
                    (event.source === EVENT_SOURCE_JIMO_API &&
                      hasFlag(F_EVENT_HAS_BEEN_SENT_BY_SDK, event.flags) ===
                        true)
                )}
                segmentioEvents={events.filter(
                  (event) => event.source === EVENT_SOURCE_SEGMENT
                )}
                posthogEvents={events.filter(
                  (event) => event.source === EVENT_SOURCE_POSTHOG
                )}
                mixpanelEvents={events.filter(
                  (event) => event.source === EVENT_SOURCE_MIXPANEL
                )}
                onCreateOnTheFlyEvent={() => {
                  setEvolution({
                    ...evolution,
                    onTheFlyEvent: {
                      temporary: true,
                      conditions: [],
                      logic: buildLogic(),
                    },
                    events: [],
                  });
                }}
                onSelectEvent={(event) => {
                  setEvolution({
                    ...evolution,
                    event: event,
                    eventId: event.uid,
                  });
                }}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EventModal;
