import Button from 'components/Button';
import Divider from 'components/Divider';
import React from 'react';
import './_Styles.scss';

const HelpSection = ({isDisconnecting, onDisconnect}) => {
  return (
    <div className="slack-connect-settings-help-section">
      <div className="settings-card">
        <div className="section section-resources">
          <div className="subtitle-4">Resources</div>
          <div className="section-content">
            <a
              href="https://help.usejimo.com/help-center/integrations/crisp"
              target="_blank"
              rel="noreferrer">
              <Button iconLeft="isax isax-book" thin>
                Documentation
              </Button>
            </a>
            <a href="https://crisp.chat" target="_blank" rel="noreferrer">
              <Button iconLeft="isax isax-global" thin>
                Website
              </Button>
            </a>
          </div>
        </div>
        <Divider />
        <div className="section section-disconnect">
          <Button thin danger onClick={onDisconnect} loading={isDisconnecting}>
            Disconnect integration
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HelpSection;
