/* eslint-disable import/no-anonymous-default-export */
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';

// Startup
export const FEATURE_TOURS_LIMITED = 'FEATURE_TOURS_LIMITED';
export const FEATURE_BANNERS_LIMITED = 'FEATURE_BANNERS_LIMITED';
export const FEATURE_HINTS_LIMITED = 'FEATURE_HINTS_LIMITED';
export const FEATURE_SURVEYS_LIMITED = 'FEATURE_SURVEYS_LIMITED';
export const FEATURE_CHECKLIST_LIMITED = 'FEATURE_CHECKLIST_LIMITED';
export const FEATURE_MULTIPLE_LANGUAGES_LIMITED =
  'FEATURE_MULTIPLE_LANGUAGES_LIMITED';
export const FEATURE_ENVIRONMENT_LIMITED = 'FEATURE_ENVIRONMENT_LIMITED';
export const FEATURE_TRACKERS_LIMITED = 'FEATURE_TRACKERS_LIMITED';
export const FEATURE_THEME_LIMITED = 'FEATURE_THEME_LIMITED';
export const FEATURE_FILE_UPLOAD_SIZE = 'FEATURE_FILE_UPLOAD_SIZE';
export const FEATURE_SUPPORT = 'FEATURE_SUPPORT';
// Growth
export const FEATURE_EXPERIENCES_UNLIMITED = 'FEATURE_EXPERIENCES_UNLIMITED';
export const FEATURE_THEME_UNLIMITED = 'FEATURE_THEME_UNLIMITED';
export const FEATURE_MULTIPLE_LANGUAGES_UNLIMITED =
  'FEATURE_MULTIPLE_LANGUAGES_UNLIMITED';
export const FEATURE_SUCCESS_TRACKER = 'FEATURE_SUCCESS_TRACKER';
export const FEATURE_CUSTOM_ROLES = 'FEATURE_CUSTOM_ROLES';
export const FEATURE_FILE_UPLOAD_SIZE_EXTENDED =
  'FEATURE_FILE_UPLOAD_SIZE_EXTENDED';
export const FEATURE_LINK_SHARING = 'FEATURE_LINK_SHARING';
export const FEATURE_DEDICATED_CSM = 'FEATURE_DEDICATED_CSM';
export const FEATURE_WHITE_LABEL = 'FEATURE_WHITE_LABEL';
export const FEATURE_INTEGRATION_ADVANCED = 'FEATURE_INTEGRATION_ADVANCED';
export const FEATURE_INTEGRATION_HUBSPOT = 'FEATURE_INTEGRATION_HUBSPOT';
export const FEATURE_ENVIRONMENT_EXTENDED = 'FEATURE_ENVIRONMENT_EXTENDED';
export const FEATURE_TRACKERS_EXTENDED = 'FEATURE_TRACKERS_EXTENDED';

// Scale
export const FEATURE_TRACKERS_UNLIMITED = 'FEATURE_TRACKERS_UNLIMITED';
export const FEATURE_ENVIRONMENT_CUSTOM = 'FEATURE_ENVIRONMENT_CUSTOM';
export const FEATURE_PRIORITY_SUPPORT = 'FEATURE_PRIORITY_SUPPORT';
export const FEATURE_EXECUTIVE_REVIEW = 'FEATURE_EXECUTIVE_REVIEW';
export const FEATURE_DATA_MIGRATION = 'FEATURE_DATA_MIGRATION';
export const FEATURE_CUSTOM_INVOICING = 'FEATURE_CUSTOM_INVOICING';

export default {
  [PLAN_STARTUP_ID]: [
    {
      uid: FEATURE_TOURS_LIMITED,
      title: 'Up to 5 Tours, Banners & Hints',
    },
    // {
    //   uid: FEATURE_BANNERS_LIMITED,
    //   title: 'Up to 5 Banners',
    // },
    // {
    //   uid: FEATURE_HINTS_LIMITED,
    //   title: 'Up to 5 Hints',
    // },
    {
      uid: FEATURE_SURVEYS_LIMITED,
      title: 'Up to 2 Surveys & NPS',
    },
    {
      uid: FEATURE_CHECKLIST_LIMITED,
      title: 'Up to 1 Checklist',
    },
    {
      uid: FEATURE_TRACKERS_LIMITED,
      title: 'Up to 2 Trackers',
    },
    {
      uid: FEATURE_THEME_LIMITED,
      title: 'Up to 1 Theme',
    },
    {
      uid: FEATURE_MULTIPLE_LANGUAGES_LIMITED,
      title: 'Up to 2 Languages',
    },
    {
      uid: FEATURE_ENVIRONMENT_LIMITED,
      title: 'Up to 2 Environments',
    },
    {
      uid: FEATURE_FILE_UPLOAD_SIZE,
      title: 'Upload file up to 1Mb',
    },
    {
      uid: FEATURE_SUPPORT,
      title: 'Email and chat support',
    },
  ],
  [PLAN_GROWTH_ID]: [
    {
      uid: FEATURE_EXPERIENCES_UNLIMITED,
      title: 'Unlimited Tours, Banners, Hints, Surveys & Checklists',
    },
    {
      uid: FEATURE_THEME_UNLIMITED,
      title: 'Unlimited Themes',
    },
    {
      uid: FEATURE_MULTIPLE_LANGUAGES_UNLIMITED,
      title: 'Unlimited Languages',
    },
    {
      uid: FEATURE_TRACKERS_EXTENDED,
      title: 'Up to 10 Trackers',
    },
    {
      uid: FEATURE_ENVIRONMENT_EXTENDED,
      title: 'Up to 4 Environments',
    },
    {
      uid: FEATURE_FILE_UPLOAD_SIZE_EXTENDED,
      title: 'Upload file up to 3Mb',
    },
    {
      uid: FEATURE_CUSTOM_ROLES,
      title: 'Custom Roles',
    },
    {
      uid: FEATURE_LINK_SHARING,
      title: 'Link sharing',
    },
    {
      uid: FEATURE_WHITE_LABEL,
      title: 'Hide Jimo label',
    },
    {
      uid: FEATURE_INTEGRATION_ADVANCED,
      title: 'Slack, Hubspot, Posthog & Mixpanel Integrations',
    },
    {
      uid: FEATURE_DEDICATED_CSM,
      title: 'Dedicated Customer Support Management',
    },
  ],
  [PLAN_SCALE_ID]: [
    {
      uid: FEATURE_TRACKERS_UNLIMITED,
      title: 'Unlimited Trackers',
    },
    {
      uid: FEATURE_ENVIRONMENT_CUSTOM,
      title: 'Custom Environments',
    },
    {
      uid: FEATURE_DATA_MIGRATION,
      title: 'Data Migration',
    },
    {
      uid: FEATURE_PRIORITY_SUPPORT,
      title: 'Priority Support',
    },
    {
      uid: FEATURE_EXECUTIVE_REVIEW,
      title: 'Executive Business Review',
    },
    {
      uid: FEATURE_CUSTOM_INVOICING,
      title: 'Custom Invoicing',
    },
  ],
};
