import Axios from 'axios';
import {generalSelector} from 'selectors';

export const F_SEGMENT_FROM_DATA_IMPORT = 1;

export const CATEGORY_ATTRIBUTES = 'ATTRIBUTES';
export const CATEGORY_ACTIVITY = 'ACTIVITY';
export const CATEGORY_SEGMENTIO_FIELDS = 'SEGMENTIO_FIELDS';
export const CATEGORY_SEGMENTIO_EVENTS = 'SEGMENTIO_EVENTS';
export const CATEGORY_TRACKED_EVENTS = 'EVENTS';
export const CATEGORY_CUSTOM_ATTRIBUTES = 'CUSTOM';
export const CATEGORY_CIO_EVENTS = 'CIO_EVENTS';
export const CATEGORY_POSTHOG_EVENTS = 'POSTHOG_EVENTS';
export const CATEGORY_MIXPANEL_EVENTS = 'MIXPANEL_EVENTS';
export const CATEGORY_JIMO_API_EVENTS = 'JIMO_API_EVENTS';

export const CUSTOM_ATTRIBUTE_SOURCE_JIMO = 'JIMO';
export const CUSTOM_ATTRIBUTE_SOURCE_HUBSPOT = 'HUBSPOT';
export const CUSTOM_ATTRIBUTE_SOURCE_SEGMENT = 'SEGMENT';

export const SEGMENT_ICONS = [
  {
    value: 'DEFAULT',
    icon: (
      <div className="icon-wrapper default">
        <i className="icon-radiobox-o" />
      </div>
    ),
  },
  {
    value: 'EVALUATORS',
    icon: (
      <div className="icon-wrapper evaluators">
        <i className="icon-start-1" />
      </div>
    ),
  },
  {
    value: 'BEGINNERS',
    icon: (
      <div className="icon-wrapper beginners">
        <i className="icon-start-2" />
      </div>
    ),
  },
  {
    value: 'REGULAR',
    icon: (
      <div className="icon-wrapper regular">
        <i className="icon-start-3" />
      </div>
    ),
  },
  {
    value: 'CHAMPIONS',
    icon: (
      <div className="icon-wrapper champions">
        <i className="icon-start-4" />
      </div>
    ),
  },
];

// Endpoint
const EP_SEGMENT_CREATE = '/segment';
const EP_SEGMENT_UPDATE = (segmentId) => `/segment/${segmentId}`;
const EP_SEGMENT_DUPLICATE = (segmentId) => `/segment/duplicate/${segmentId}`;
const EP_SEGMENT_GET = '/segment';
const EP_SEGMENT_GET_BY_ID = (segmentId) => `/segment/${segmentId}`;
const EP_SEGMENT_DELETE = (segmentId) => `/segment/${segmentId}`;
const EP_SEGMENT_ATTRIBUTES_UPDATE = (segmentId) =>
  `/segment/${segmentId}/attributes`;
const EP_SEGMENT_CUSTOM_ATTRIBUTES_GET = '/segment/custom-attributes';
const EP_SEGMENT_JIMERS_GET = (segmentId) => `/segment/${segmentId}/jimers`;
const EP_SEGMENT_CUSTOM_ATTRIBUTE_CREATE = '/segment/custom-attribute';

// Methods
export const createSegment = (data) => {
  const {name, description, temporary, evolutionId, icon} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_SEGMENT_CREATE, {
    name,
    description,
    temporary,
    icon,
    evolutionId,
    projectId,
  }).then((response) => response.data);
};
export const updateSegment = (segmentId, data) => {
  const {name, description, icon} = data;

  return Axios.put(EP_SEGMENT_UPDATE(segmentId), {
    name,
    description,
    icon,
  }).then((response) => response.data);
};

export const duplicateSegment = (segmentId) => {
  return Axios.post(EP_SEGMENT_DUPLICATE(segmentId), {
    segmentId,
  }).then((response) => response.data);
};

export const deleteSegment = (segmentId) => {
  return Axios.delete(EP_SEGMENT_DELETE(segmentId)).then(
    (response) => response.data
  );
};
export const getSegments = ({
  relations,
  projectId = generalSelector.getProject().uid,
  evolutionId,
} = {}) => {
  return Axios.get(EP_SEGMENT_GET, {
    params: {projectId, relations, evolutionId},
  }).then((response) => response.data);
};
export const getSegmentById = (segmentId) => {
  return Axios.get(EP_SEGMENT_GET_BY_ID(segmentId)).then(
    (response) => response.data
  );
};
export const updateSegmentAttributes = (segmentId, data) => {
  let {attributes, logic} = data;

  return Axios.put(EP_SEGMENT_ATTRIBUTES_UPDATE(segmentId), {
    attributes,
    logic,
  }).then((response) => response.data);
};
export const getSegmentCustomAttributes = (
  projectId = generalSelector.getProject().uid
) => {
  return Axios.get(EP_SEGMENT_CUSTOM_ATTRIBUTES_GET, {
    params: {projectId},
  }).then((response) => response.data);
};
export const getSegmentJimers = (segmentId, data, {signal = null}) => {
  const {skip, take} = data;

  return Axios.get(EP_SEGMENT_JIMERS_GET(segmentId), {
    signal,
    params: {skip, take},
  }).then((response) => response.data);
};
export const createCustomAttribute = (data) => {
  const {attributeName, source} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_SEGMENT_CUSTOM_ATTRIBUTE_CREATE, {
    attributeName,
    projectId,
    source,
  }).then((response) => response.data);
};
