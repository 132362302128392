import {useContext} from 'react';
import {EventContext} from '../..';
import './_Styles.scss';

const EventProperties = () => {
  const {event} = useContext(EventContext);

  const {properties = []} = event || {};

  return (
    <>
      <div className="event-properties">
        <div className="event-properties-title">Properties</div>
        <div className="event-properties-table">
          <div className="top">
            <div className="n-500 body-3">Name</div>
          </div>
          <div className="list">
            {properties
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((property) => {
                return (
                  <div key={property.uid} className="card-property">
                    <div className="property-name">
                      <span className="name n-700">{property.name}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventProperties;
