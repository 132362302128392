import Divider from 'components/Divider';
import TextArea from 'components/TextArea';
import {BuilderContext} from 'contextes/builder';
import {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import {BLOCK_TYPE_OPEN_QUESTION} from 'services/steps';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';

const OpenQuestion = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_OPEN_QUESTION, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_OPEN_QUESTION);

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;

  return (
    <div className="block-settings open-question">
      <Section title="Content">
        <TextArea
          className="open-question-content-input"
          placeholder="Placeholder"
          value={value}
          onChange={(e) => updateBlock({value: e.target.value})}
        />
      </Section>
      <Divider />
      <Section title="Style">
        <OpenQuestionStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

export const OpenQuestionStyle = ({style, updateStyle}) => {
  return (
    <>
      <ColorItem
        title="Background"
        value={style.primaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, primaryColor: value},
          })
        }
      />
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              borderRadius: value,
            },
          })
        }
      />
    </>
  );
};

export default OpenQuestion;
