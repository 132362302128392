import Axios from 'axios';
import {generalSelector} from 'selectors';

const EP_CRISP_WIDGET_SETUP = '/crisp-widget/setup';
const EP_CRISP_WIDGET_REMOVE = '/crisp-widget/setup';

export const setup = (projectId = generalSelector.getProject().uid) => {
  return Axios.post(
    EP_CRISP_WIDGET_SETUP,
    {},
    {
      params: {projectId},
    }
  ).then((response) => response.data);
};

export const remove = (projectId = generalSelector.getProject().uid) => {
  return Axios.delete(EP_CRISP_WIDGET_REMOVE, {
    params: {projectId},
  }).then((response) => response.data);
};
