import {dataActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import {default as DefaultLoader, default as Loader} from 'components/Loader';
import {ModalConfirm} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {PermissionsPeople} from 'constants/permissions';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {array, bool, func} from 'prop-types';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_USERS_SEGMENT_WITH_ID} from 'router/routes.const';
import {segmentService} from 'services';
import {SEGMENT_ICONS} from 'services/segment';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('Users/ListSegments');

const propTypes = {
  segments: array,
  isLoading: bool,
  refetchSegments: func,
};

const defaultProps = {
  segments: [],
  isLoading: false,
  refetchSegments: () => {},
};

const ListSegments = ({segments, isLoading, refetchSegments}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setSegments = (segments) => dispatch(dataActions.setSegments(segments));

  const [loading, setLoading] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);

  const handleDuplicate = async (segmentId) => {
    setLoading(segmentId);
    try {
      const newSegment = await segmentService.duplicateSegment(segmentId);
      history.push(ROUTE_USERS_SEGMENT_WITH_ID(newSegment.uid));
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Duplicating segment failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setLoading(null);
    }
  };

  const handleDelete = async (segmentId) => {
    setLoading(segmentId);
    try {
      await segmentService.deleteSegment(segmentId);

      setSegments(segments.filter((s) => s.uid !== segmentId));
      setShowDeleteConfirm(null);
      await refetchSegments();
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting segment failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setLoading(null);
    }
  };

  const classNames = classnames('users-list-segments', {
    'is-loading': isLoading,
    'is-empty': isLoading !== true && segments.length === 0,
  });

  const canDeleteSegment = hasPermissions(PermissionsPeople.SEGMENT_DELETE);

  if (isLoading) {
    return (
      <div className={classNames}>
        <DefaultLoader />
      </div>
    );
  }

  if (segments.length === 0) {
    return (
      <div className={classNames}>
        <div className="empty-state-wrapper">
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyResults}
            title="No segments found"
            description="Create a segment to start targeting specific users"
          />
          <Button
            primary
            iconLeft={'icon-plus'}
            onClick={() => history.push(ROUTE_USERS_SEGMENT_WITH_ID('new'))}>
            Create your first segment
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classNames}>
        <div className="segments-table">
          <div className="top">
            <div></div>
            <div className="body-3 n-500">Name</div>
            <div className="body-3 n-500">Audience</div>
            <div className="body-3 n-500">Created</div>
            <div className="body-3 n-500">Last updated</div>
          </div>
          {isLoading === true && (
            <div className="loader-wrapper">
              <Loader width={24}></Loader>
            </div>
          )}
          {isLoading === false && (
            <div className="list">
              {segments
                .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
                .map((segment) => {
                  return (
                    <div
                      key={segment.uid}
                      className="card-segment"
                      onClick={() => {
                        history.push(ROUTE_USERS_SEGMENT_WITH_ID(segment.uid));
                      }}>
                      <div className="segment-icon-wrapper">
                        {!segment.icon ||
                        SEGMENT_ICONS.map((i) => i.value).includes(
                          segment.icon
                        ) ? null : (
                          <div className="segment-icon">{segment.icon}</div>
                        )}
                      </div>
                      <div className="segment-name">
                        <span className="name">{segment.name}</span>
                        <span className="description">
                          {segment.description}
                        </span>
                      </div>
                      <div className="segment-audience">
                        {segment.loading === true ? (
                          <Tooltip
                            trigger={
                              <div className="segment-populating body-3">
                                <DefaultLoader width="8px" />
                                Populating
                              </div>
                            }>
                            Depending on the number of jimers found, <br /> this
                            take can a few minutes to finish.
                          </Tooltip>
                        ) : (
                          <span>{segment.size}</span>
                        )}
                      </div>
                      <div className="segment-created">
                        <span className="body-3 n-800">
                          {dayjs(segment.createdAt).fromNow()}
                        </span>
                        {segment.author && (
                          <span className="body-4 n-500">
                            By {segment.author.username}
                          </span>
                        )}
                      </div>
                      <div className="segment-last-edited">
                        <span className="body-3 n-800">
                          {dayjs(segment.lastEditedAt).fromNow()}
                        </span>
                        {segment.lastEditor && (
                          <span className="body-4 n-500">
                            By {segment.lastEditor.username}
                          </span>
                        )}
                      </div>
                      {canDeleteSegment === true && (
                        <div className="segment-actions">
                          <Button
                            iconOnly
                            thin
                            rounded={false}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              handleDuplicate(segment.uid);
                            }}
                            loading={loading === segment.uid}
                            disabled={
                              loading != null && loading !== segment.uid
                            }>
                            <i className="isax isax-copy" />
                          </Button>
                          <Button
                            iconOnly
                            thin
                            rounded={false}
                            danger
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteConfirm(segment.uid);
                            }}
                            loading={loading === segment.uid}
                            disabled={
                              loading != null && loading !== segment.uid
                            }>
                            <i className="isax isax-trash" />
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <ModalConfirm
        isOpen={showDeleteConfirm != null}
        isConfirming={loading === true}
        onConfirm={() => {
          handleDelete(showDeleteConfirm);
        }}
        onCancel={() => {
          setShowDeleteConfirm(null);
        }}
      />
    </>
  );
};

ListSegments.propTypes = propTypes;
ListSegments.defaultProps = defaultProps;

export default ListSegments;
