import Axios from 'axios';

// Endpoint
const EP_EVENT_PROPERTY_VALUES_GET = (eventPropertyId) =>
  `/event-property/${eventPropertyId}/values`;

// Methods
export const getEventPropertyValues = (eventPropertyId, data) => {
  const {skip, take, search} = data;

  return Axios.get(EP_EVENT_PROPERTY_VALUES_GET(eventPropertyId), {
    params: {skip, take, search},
  }).then((response) => response.data);
};
